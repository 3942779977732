<template>
  <div class="scheme-contain">
    <div class="affixion-contain">
      <Form :label-width="115">
        <Row>
          <Col span="8" offset="7">
            <Form-item label="图片类型：">
              <Select placeholder="请选择"  v-model="checkPrint.type">
                <Option v-for="item in picTypeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </Form-item>
            <Form-item label="检查日期：">
              <Date-picker type="date" placeholder="选择日期" :value="checkPrint.date" @on-change="changeDate"></Date-picker>
            </Form-item>
            <Form-item label="上传图片：">
              <Upload action="/api/check/photo/upload"
                      name="photo"
                      ref="upload"
                      :on-success="handleUploadSuccess"
                      :on-exceeded-size="handleMaxSize"
                      :on-remove="handleRemove"
                      :before-upload="handleBeforeUpload">
                <Button icon="ios-cloud-upload-outline" v-model="checkPrint.date">上传</Button>
              </Upload>
            </Form-item>
            <Form-item label="图片描述：">
              <Input type="textarea" :autosize="{minRows: 5,maxRows: 10}" :maxlength="200" placeholder="请输入..." v-model="checkPrint.describe" @on-change="handleLen"></Input>
              <p class="text-info">注：最多只能输入200个字</p>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			picFormat: ['jpg', 'jpeg', 'png'],
			picData: {},
			uploadList: [],
			picTypeList: [],
			commitMessage: '',
			checkPrint: {
				type: '',
				date: '',
				image: '',
				describe: '',
			},
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},

		picId() {
			return this.$route.params.pic_id;
		},
	},

	created() {
		MemberService.listPicType().then((data) => {
			this.picTypeList = data;
		});

		this.checkPrint.type = this.picId;
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},

	methods: {
		changeDate(date) {
			this.checkPrint.date = date;
		},

		handleUploadSuccess(response) {
			this.picData.photo = response.data.photo;
			this.picData.id = response.data.id;
		},

		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，不能超过 2M。');
		},

		handleBeforeUpload() {
			const check = this.uploadList.length < 1;
			if (!check) {
				this.$Message.warning('最多只能上传 1 个文件。');
			}
			return check;
		},
		handleRemove() {
			this.picData.id = '';
		},
		handleSave() {
			var req = {
				member_id: this.memberId,
				type_id: this.checkPrint.type,
				check_date: this.checkPrint.date,
				photo_id: this.picData.id,
				describe: this.checkPrint.describe,
			};
			if (this.validateFormData()) {
				MemberService.addPicCheck(req).then(() => {
					// this.$Message.warning('图片增加成功')
					this.$router.go(-1);
				});
			}
		},

		handleBack() {
			this.$router.go(-1);
		},

		validateFormData() {
			if (!this.checkPrint.type) {
				this.$Message.warning('请选择图片类型');
				return false;
			} else if (!this.checkPrint.date) {
				this.$Message.warning('请选择检查日期');
				return false;
			} else if (!this.picData.id) {
				this.$Message.warning('请上传图片');
				return false;
			} else if (!this.checkPrint.describe) {
				this.$Message.warning('请输入图片描述');
				return false;
			} else {
				return true;
			}
		},

		handleLen() {
			if (this.checkPrint.describe.length > 199) {
				this.$Message.warning('最多只能输入200个字');
			}
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
  padding-bottom: 20px;
}
.text-info {
  color: #999;
}
</style>
